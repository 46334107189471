<template>
  <div class="order-view">
        <div class="order-view__options w-full flex">
      <div class=" w-3/4 ">
        <div class="order-view__viewer bg-white rounded shadow-md p-1 ">
          <div id="order-pdf-viewer"></div>
        </div>
      </div>
      <div class="w-1/4 flex flex-col pl-4" v-if="order">

        <div class="text-base font-bold mb-2">Aktionen</div>

        <vs-button icon="send" color="success" class="mb-2" @click="openSendInvoiceModal">Versenden</vs-button>

        <vs-button icon="get_app" color="success" class="mb-2" target :href="documentUrl+'?download=1'">PDF
          Download
        </vs-button>

        <vs-button icon="euro_symbol" color="success" class="mb-2 link">
            <router-link
                :to="{name:'offers-accounting-already-billed'}"
                class="NavigationItem__router-link">Rechnung erzeugen (NICHT VERWENDEN)
            </router-link>
        </vs-button>

        <vs-button icon="euro_symbol" color="success" class="mb-2 link">
          <router-link
              :to="{name:'offers-accounting-create-invoice'}"
              class="NavigationItem__router-link"> Akontorechnung erzeugen
          </router-link>
        </vs-button>

        <vs-button icon="euro_symbol" color="success" class="mb-2 link">
          <router-link
              :to="{name:'offers-accounting-create-final-invoice'}"
              class="NavigationItem__router-link"> Schlussrechnung erzeugen
          </router-link>
        </vs-button>

        <template v-if="!order.type.is_confirmation">
          <vs-button icon="verified_user" color="success" class="mb-2"
                     :to="'/order-confirmation/create/'+order.id">Auftragsbestätigung erstellen
          </vs-button>
        </template>

        <template v-if="order.type.is_confirmation">
          <vs-button icon="create" type="border" color="success" class="mb-2"  @click="onClickEditConfirmation">
            bearbeiten
          </vs-button>
        </template>

        <vs-button icon="remove_circle_outline" color="danger" class="mb-8" @click="onClickDeleteOrder">
          {{order.type.name}} löschen
        </vs-button>

        <vs-prompt
            @accept="onDeleteOrder"
            :title="`${order.type.name} löschen`"
            @close="deleteOrderPrompt=false"
            acceptText="Löschen"
            cancelText="Abbrechen"
            :active.sync="deleteOrderPrompt">
          <p>Sind Sie sicher dass das {{order.type.name}} <u>endgültig</u> gelöscht werden soll</p>
        </vs-prompt>

        <div class="text-base font-bold mb-2">Interne Notiz</div>

        <vs-textarea label="Interne Notiz" v-model="order.notice" class="shadow-md bg-white mb-8"></vs-textarea>

        <div class="text-base font-bold mb-2">Zugeordnete Kategorien</div>

        <category-selector v-model="order.categories" type="order"
                           class="mb-8 shadow-md rounded"></category-selector>

        <div class="text-base font-bold mb-2">Zugeordnete Tags</div>

        <tag-selector v-model="order.tags" type="order" class="shadow-md rounded"></tag-selector>

        <vs-button color="success" class="mt-4" @click="onSaveOrder">Speichern</vs-button>

        <send-invoice-modal
            :send-only="true"
            :active.sync="sendInvoiceModalActive"
            :template="compiledMailTemplate"
            :emails="sendInvoiceEmails"
            :title="`${this.order.type.name} versenden`"
            :sendLabel="`${this.order.type.name} senden`"
            @send="sendInvoiceMail">

        </send-invoice-modal>

      </div>

    </div>

    <order-cancel-modal :order="order" :active.sync="orderCancelModal"></order-cancel-modal>
  </div>
</template>

<script>
import * as PDFObject from 'pdfobject';
import ApiService from "../../../api";
import Datepicker from '@sum.cumo/vue-datepicker';
import moment from 'moment';
import OrderCancelModal from "../../../components/order/OrderCancelModal";
import PayDatePrompt from "../../../components/invoice/PayDatePrompt";
import SendInvoiceModal from "../../../components/invoice/SendInvoiceModal";
import CategorySelector from "../../../components/search/CategorySelector";
import TagSelector from "../../../components/search/TagSelector";
import TaskAllocation from "../../../components/commision/TaskAllocation";
import * as qs from "qs";
import vSelect from 'vue-select'
import staticOptions from "../../../mixins/static/options";
import OfferHeaderNavigation from "../../../components/offers-details/HeaderNavigation"
import {mapGetters} from "vuex";

export default {
  name: "ViewOffer",
  components: {
    // History,
    TaskAllocation,
    TagSelector,
    CategorySelector,
    SendInvoiceModal,
    PayDatePrompt,
    OrderCancelModal,
    Datepicker,
    'v-select': vSelect,
    OfferHeaderNavigation
  },
  data() {
    return {
      order: null,
      order_status: [],
      activePayedPrompt: false,
      orderCancelModal: false,
      deleteOrderPrompt: false,
      sendInvoiceModalActive: false,
      compiledMailTemplate: null,
      permission_edit_open_orders: false,
      toolbar: [
        ['bold', 'italic', 'underline'],
        [{'list': 'ordered'}, {'list': 'bullet'}],
        [{'color': []}],
      ],
    }
  },
  computed: {
    ...mapGetters(['workspace']),
    sendInvoiceEmails() {
      if (!this.order) {
        return null;
      }

      if(!this.order.client) {
        return null;
      }

      return this.order.client.invoice_emails;
    },

    invoiceRecipientContact() {
      if (!this.order) {
        return null;
      }

      if (this.order.client.contacts === null) {
        return null;
      }

      const contact = this.order.client.contacts.filter(item => item.pivot.is_invoice_recipient);

      if (contact) {
        return contact
      }

      return null;
    },

    additionalInvoiceRecipient() {
      if (!this.order) {
        return null;
      }

      if (this.order.client.additional_invoice_recipient) {
        this.order.client.additional_invoice_recipient.split(';');
      }

      return null;
    },
    documentUrl() {
      if (!this.order) {
        return null;
      }

      return `${process.env.VUE_APP_API_URL}document/${this.workspace.id}/order/${this.order.type.invoice_type}/${this.order.file}`;
    }
  },
  mounted() {
    this.fetchData()
  },
  methods: {
    async fetchData() {
      this.$vs.loading();

      const id = this.$route.params.id;
      const version = Math.floor((Math.random() * 1000000) + 1);

      const orderStatusPromise = ApiService.get('order_status?filter[invoice_type]=offer');
      const orderDataPromise = ApiService.get(`orders/${id}`);

      this.checkIfUserHasPermission();

      return Promise.all([orderStatusPromise, orderDataPromise]).then(response => {
        if (response[1].status !== 200 || response[0].status !== 200) {
          return this.$router.push('/invoices');
        }

        this.order_status = response[0].data.result;
        this.order = response[1].data.result;

        if (this.isDraft) {
          return this.$router.push('/invoices/' + this.order.id);
        }

        PDFObject.embed(`${process.env.VUE_APP_API_URL}document/${this.workspace.id}/order/${this.order.type.invoice_type}/${this.order.file}?v=${version}`, '#order-pdf-viewer');

        this.$vs.loading.close()
      }).catch((response) => {

        console.log(response)

        this.$vs.loading.close();
        this.$router.push('/invoices');
      });
    },

    onClickDeleteOrder() {
      this.deleteOrderPrompt = true;
    },
    onDeleteOrder() {
      this.$vs.loading();

      ApiService.delete(`orders/${this.$route.params.id}`).then(response => {
        if (response.status === 200) {
          this.$vs.loading.close();
          this.$router.push('/offers');
        }
      });
    },
    checkIfUserHasPermission() {
      return ApiService.post(`auth/functionPermission`, {permissionKey: "edit_open_orders"})
          .then(response => {
            if (response.data.status == "success") {
              this.permission_edit_open_orders = true;

            } else {
              this.permission_edit_open_orders = false;
            }
          });
    },
    openSendInvoiceModal() {
      const typeId = this.$route.params.id;
      const name = this.order.type.mail_template.name;
      const language = this.order.client.country == 1 ? 'de':'en';

      ApiService.get(`mail-templates/${name}/order/${typeId}/${language}`).then((response) => {
        if (response.status !== 200) {
          return;
        }

        this.compiledMailTemplate = response.data.result;
        this.sendInvoiceModalActive = true;
      });

    },
    onSaveOrder() {
      const id = this.$route.params.id;
      const postData = {
        categories: this.order.categories,
        tags: this.order.tags,
        notice: this.order.notice,
        status: this.order.order_status.id
      };

      this.$vs.loading();

      ApiService.put(`orders/${id}`, postData).then(response => {
        if (response.status === 200) {
          this.$vs.loading.close();

          this.$vs.notify({
            title: 'Erfolgreich',
            text: 'Die Änderung wurde erfolgreich gespeichert',
            iconPack: 'feather',
            icon: 'icon-alert-circle',
            color: 'success'
          });
        }
      }).catch(response => {
        this.$vs.loading.close();

        this.$vs.notify({
          title: 'Fehler',
          text: response.message,
          iconPack: 'feather',
          icon: 'icon-alert-circle',
          color: 'danger'
        })
      });
    },
    saveOrderAccountingFinished(item){

      const id = item.id;
      const postData = {
        is_accounting_finished: true,
      };

      this.$vs.loading();

      ApiService.put(`order_items/${id}`, postData).then(response => {
        if (response.data.status === 'success') {
          this.$vs.loading.close();
          item.is_accounting_finished = true;
          item.amount = null;
          item.add_to_accounting = false;

          this.$vs.notify({
            title: 'Erfolgreich',
            text: 'Die Änderung wurde erfolgreich gespeichert',
            iconPack: 'feather',
            icon: 'icon-alert-circle',
            color: 'success'
          });
        }
      }).catch(response => {
        this.$vs.loading.close();

        this.$vs.notify({
          title: 'Fehler',
          text: response.message,
          iconPack: 'feather',
          icon: 'icon-alert-circle',
          color: 'danger'
        })
      });
    },

    sendInvoiceMail({emails, content, attachments, from, cc, bcc, subject, personal}) {
      let formData = new FormData();

      formData.append('emails', emails);
      formData.append('content', content);
      formData.append('mail_template_id', this.order.type.mail_template_id);
      formData.append('subject', subject);
      formData.append('from', from);
      formData.append('cc', cc);
      formData.append('bcc', bcc);
      formData.append('personal', personal);

      if (attachments) {
        for (var i = 0; i < attachments.length; i++) {
          formData.append('additional_attachments[' + i + ']', attachments[i]);
        }
      }

      this.$vs.loading();

      ApiService.post('orders/mail/' + this.order.id, formData, {headers: {'Content-Type': 'multipart/form-data'}}).then((response) => {
        this.$vs.loading.close();

        this.$vs.notify({
          title: 'Erfolgreich',
          text: 'Die Email wurde erfolgreich versendet',
          iconPack: 'feather',
          icon: 'icon-alert-circle',
          color: 'success'
        });
        this.sendInvoiceModalActive = false;
        this.fetchOrderHistory();

      }).catch((error) => {
        this.$vs.loading.close();

        if (error.response.data.message) {
          this.$vs.notify({
            title: 'Fehler',
            text: error.response.data.message,
            time: 6000,
            iconPack: 'feather',
            icon: 'icon-alert-circle',
            color: 'danger'
          })
        } else {
          this.$vs.notify({
            title: 'Fehler',
            text: error.message,
            time: 6000,
            iconPack: 'feather',
            icon: 'icon-alert-circle',
            color: 'danger'
          })
        }

      });
    },
    onClickEditConfirmation() {
      this.$vs.dialog({
        type: 'confirm',
        color: 'primary',
        title: 'Bestätigen',
        text: 'Bist du dir sicher, dass du eine Auftragsbestätigung bearbeiten darfst?',
        accept: this.gotoEditPage,
        acceptText: 'Ja, bearbeiten',
        cancelText: 'Abbrechen'
      })
    },
    gotoEditPage() {
      this.$router.push('/offers/' + this.order.id)
    },
    formatDate(value) {
      return moment(new Date(value)).format('DD.MM.YYYY')
    }
  }
}
</script>

<style lang="scss">
.pdfobject-container {
  height: 700px;
}
.offer2accounting--popup {
  .vs-popup {
    width: auto;
    min-width: 80vw;
    max-width: calc(100vw - 20vw);
    .vs-popup--content {
      overflow: auto;
      .position {
        &:nth-of-type(even) {
          background: #efefef;
        }
        > .flex > div {
          padding: 15px;
        }
        .description {
          font-size: 12px;
          color: #a0a0a0;
          margin-top: 6px;
        }
        .accounting_finished {
          opacity: 0.3;
        }
        .name {
          display: inline;
        }

      }
    }
  }
  .small-alert {
    height: auto!important;
  }
}
.link {
  span {
    a {
      color: #FFFFFF !important;
    }
  }
}
</style>
